import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import {
  CreditsWageredByMachineChart,
  type DataItem,
} from './CreditsWageredByMachineChart'
import { useLayout } from 'hooks/useLayout'
import { useGetCreditsWageredByMachine } from 'hooks/api/Reports/useGetCreditsWageredByMachine'
import { useSetLe } from 'stores/useSetLe'
import { GamingReportsHeader } from '../GamingReportsHeader'
import { useState } from 'react'
import { WeekRangeSelect } from 'components/WeekRangeSelect'
import { getReportStartAndEndDates } from 'utils/util'

export const CreditsWageredByMachinePage = () => {
  const { passedLe } = useSetLe()

  const [numDays, setNumDays] = useState<string>('56')

  const dates = getReportStartAndEndDates(Number(numDays) / 7, 1)
  const calcStartDate = dates.startDate
  const endDate = dates.endDate

  const isMobile = useLayout()

  const [, setStartDate] = useState<Date>(calcStartDate)

  const reportQuery = useGetCreditsWageredByMachine({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate: calcStartDate,
    endDate,
  })

  const formattedStartDate = format(calcStartDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  return (
    <GamingReportsHeader currentTab={'1'}>
      <WeekRangeSelect
        label={`Weeks of: ${formattedStartDate} - ${formattedEndDate}`}
        numDays={numDays}
        setStartDate={setStartDate}
        setNumDays={setNumDays}
        displayWeeks={[4, 8, 16]}
      >
        <Stack spacing={4}>
          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4"></Typography>
            ) : (
              <CreditsWageredByMachineChart
                data={reportQuery.data.metrics.map(
                  (item) =>
                    ({
                      amount: Number(item.amount),
                      name: String(item.name),
                      tagNumber: Number(item.tagNumber),
                      vgtNumber: String(item.vgtNumber),
                      percentage: Number(item.percentage),
                    }) satisfies DataItem
                )}
                mobile={isMobile.isMobile}
              />
            ))}
        </Stack>
      </WeekRangeSelect>
    </GamingReportsHeader>
  )
}
