import { isPresent } from '@jjvgaming/player-payback-library'
import _ from 'lodash'

export const allPaths = [
  '/',
  '/CreateAccount',
  '/Home',
  '/Development/Home',
  '/Compliance',
  '/ContactUs',
  '/PatronCheckIns',
  '/Locations',
  '/FlexPay',
  '/JJNews/Newsletters',
  '/JJNews/News',
  '/JJNews/Resources',
  '/GamingReports',
  '/GamingReports/GamingReportsPage',
  '/GamingReports/CreditsWageredByMachine',
  '/GamingReports/WeeklySnapshot',
  '/GamingReports/WeeklySnapshotLocationSelect',
  '/GamingReports/CreditsWageredByMachine',
  '/GamingReports/CreditsWageredByHour',
  '/GamingReports/VgtSnapshot',
  '/GamingReports/VgtTrends',
  '/GamingReports/CreditsWageredHourlyTrends',
  '/GamingReports/TrailingNtiTrends',
  '/PlayerPaybackReports/PatronActivity',
  '/PlayerPaybackReports/PatronList',
  '/PlayerPaybackReports/PatronRewardRedemptions',
  '/PlayerPayback',
  '/Rewards',
  '/Specials&Events',
  '/Specials&Events/:id',
  '/Sweepstakes',
  '/Sweepstakes/:id/Overview',
  '/Sweepstakes/:id/Winners',
  '/Sweepstakes/:id/ParticipatingLocations',
  '/Sweepstakes/:id/EnrollLocations',
  '/Sweepstakes/:id/AdditionalEnrollmentDetails',
  '/Sweepstakes/:id/ReviewLocationsToEnroll',
  '/LicensedEstablishments/:id',
  '/LicensedEstablishmentUsers',
  '/LicensedEstablishmentUsers/NewLEConnectUserPage',
  '/LicensedEstablishmentUsers/LEConnectUserDetailsPage/:id',
  // '/LiveAtLocation',
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId',
  // '/LiveAtLocation/PatronDetails/:id',
  '/RewardsProgram/:id',
  // '/RewardsProgram/:id/Settings',
  '/RewardsProgram/:id/Redemption',
  '/RewardsProgram/:id/ActivityLog',
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId',
] as const

const allPathsAsRegExps = allPaths.map(
  (path) => new RegExp('^' + path.replace(/:\w*id/gi, '\\d+') + '$')
)

export const findPath = (path: string) => {
  const foundPath = _.find(allPathsAsRegExps, (x) => x.test(path))
  if (!foundPath) {
    return null
  }
  return foundPath
}

export type Path = (typeof allPaths)[number]
export const isPath = (maybePath: string): maybePath is Path =>
  isPresent(findPath(maybePath))
