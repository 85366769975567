import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { ActivityButton } from 'components/ActivityButton'
import { colorPrimitives, Colors } from 'components/Theme'
import {
  formatCurrency,
  formatFullDate,
  pdfDownloadFromByteArray,
} from 'utils/util'
import { useGetDownloadFlexPayStatements } from 'hooks/api/Reports/useGetDownloadFlexPayStatements'
import { useSnackbar } from 'stores/useSnackbar'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import {
  type FullLicensedEstablishmentFlexPayStatementDTOPageResult,
  type LicensedEstablishment,
} from 'src/types/api'
import { SelectLicensedEstablishment } from 'pages/PlayerPaybackReports/SelectLicensedEstablishment'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridColDef,
  type GridPaginationModel,
} from '@mui/x-data-grid'
import { SelectFlexPayType } from 'components/SelectFlexPayType'
import { type FlexPayTypes } from 'src/types/flexPay'
import { DataTablePaging } from 'components/Shared/DataTablePaging'
import { useGetFlexPayStatements } from 'hooks/api/Reports/useGetFlexPayStatements'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS } from 'date-fns/locale'
import { isValid } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useSetContactUsFields } from 'stores/useSetContactUsFields'

const StyledDatePicker = styled(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: '1rem',
    fontWeight: '100',
    color: Colors.muiLabels.placeholder,
  },
  '& .Mui-focused': {
    color: !Colors.muiLabels.placeholder,
  },
})

const getDefaultStartDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 30)
  date.setHours(0, 0, 0, 0) // set to midnight 30 days ago
  return date
}

const getDefaultEndDate = () => {
  const date = new Date()
  date.setHours(23, 59, 59, 999)
  return date
}

export const FlexPayPage = () => {
  const navigate = useNavigate()
  const updateLocation = useSetContactUsFields(
    (state) => state.updateSelectedLocation
  )
  const flexPay = useSetContactUsFields((state) => state.flexPay)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })

  const [activeId, setActiveId] = useState<number | null>(null)
  const [downloadedReports] = useState<number[]>([])

  const setDownloadedReportNumbers = (p: any) => {
    downloadedReports.push(p)
  }

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const leId = licensedEstablishment?.id ?? 0
  const selectedLocationId = isNaN(leId) ? '' : leId

  const [type, setType] = useState<string>()

  const [startDate, setStartDate] = useState<Date>(getDefaultStartDate())
  const [endDate, setEndDate] = useState<Date>(getDefaultEndDate())

  useEffect(() => {
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
  }, [licensedEstablishment, type, leId, startDate, endDate])

  const reportQuery = useGetFlexPayStatements({
    licensedEstablishmentId: leId,
    currentPage: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    type: type !== undefined ? (type as unknown as FlexPayTypes) : null,
    startDate,
    endDate,
    enabled: leId !== 0,
  })

  const dataRows = (reportQuery.data ??
    []) as FullLicensedEstablishmentFlexPayStatementDTOPageResult

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
  }

  const useGetDownloadFlexPayStatementsMutation =
    useGetDownloadFlexPayStatements({
      onSuccess: () => {},
      onError: () => {},
    })

  const handleDownload = async (params: any) => {
    setActiveId(params.id)

    useGetDownloadFlexPayStatementsMutation.mutate(
      {
        licensedEstablishmentId:
          params.row !== undefined
            ? params.row.licensedEstablishmentId
            : params.licensedEstablishmentId,
        license: params.row !== undefined ? params.row.license : params.license,
        type: params.row !== undefined ? params.row.type : params.type,
        blobName:
          params.row !== undefined ? params.row.blobName : params.blobName,
        blobDate: params.row !== undefined ? params.row.blobDate : params.date,
      },
      {
        onSuccess: (data) => {
          setDownloadedReportNumbers(params.id)

          pdfDownloadFromByteArray(
            data,
            params.row !== undefined ? params.row.blobName : params.blobName
          )
          setActiveId(null)
          setSnackbarMessage('Report successfully downloaded')
        },
        onError: (error) => {
          setActiveId(null)
          console.error('Error:', error)
          setSnackbarMessage(
            'There was an error downloading the Report',
            'error'
          )
        },
      }
    )
  }

  const handleDownloadSelected = async () => {
    let i = -1
    for (const location of selectedLocations) {
      i++
      const params = {
        id: location,
        licensedEstablishmentId: dataRows.results?.[i].licensedEstablishmentId,
        license: dataRows.results?.[i].license,
        type: dataRows.results?.[i].type,
        blobName: dataRows.results?.[i].blobName,
        blobDate: dataRows.results?.[i].date,
      }
      await new Promise((resolve) =>
        setTimeout(resolve, Math.floor(Math.random() * 5000))
      )
      await handleDownload(params)
    }
  }

  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [justClickedEdit, setJustClickedEdit] = useState<boolean>(false)
  const handleOnChange = (newSelection: any) => {
    if (justClickedEdit) {
      setJustClickedEdit(false)
    } else {
      setSelectedLocations(newSelection)
    }
  }

  const columns: Array<GridColDef<any>> = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.1,
    },
    {
      field: 'standardName',
      headerName: 'LE Name',
      flex: 0.7,
      valueGetter: (params: {
        row: { licensedEstablishment: { standardName: string } }
      }) => params.row.licensedEstablishment.standardName,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.5,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.25,
      valueGetter: (params: { row: { date: string } }) => params.row.date,
      valueFormatter: ({ value }: { value: string }) => formatFullDate(value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.2,
      valueGetter: (params: { row: { amount: number } }) => params.row.amount,
      valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      width: 175,
      align: 'center',
      renderCell: (params: { row: any }) =>
        !downloadedReports?.includes(params.row.id) ? (
          <ActivityButton
            sx={{
              border: `1px solid ${colorPrimitives.redGaming}`,
              background: 'white',
            }}
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            fullWidth
            variant="outlined"
            className="px-6 py-2 rounded"
            onClick={async () => await handleDownload(params)}
          >
            Download
          </ActivityButton>
        ) : (
          <ActivityButton
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            color="success"
            variant="contained"
            onClick={async () => await handleDownload(params)}
          >
            Complete
          </ActivityButton>
        ),
    },
  ]

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <Page
        header={
          <PageHeader
            title="FlexPay"
            actionButton={
              <Button
                onClick={async () => {
                  updateLocation(Number(selectedLocationId))
                  flexPay()
                  await navigate('/ContactUs')
                }}
                variant="contained"
              >
                Questions
              </Button>
            }
          />
        }
      >
        <Grid container className="flex flex-column" rowGap={2} columnGap={3}>
          <SelectLicensedEstablishment
            onSelectLicensedEstablishment={setLicensedEstablishment}
            locationId={selectedLocationId}
          />
          <SelectFlexPayType
            onSelect={setType}
            type={type}
            disabledValue={selectedLocationId === 0}
          />
          <StyledDatePicker
            disabled={selectedLocationId === 0}
            disableFuture
            onChange={(date: any) => {
              if (isValid(date)) {
                setStartDate(date.setHours(0, 0, 0, 0))
              }
            }}
            label="Start Date"
            value={startDate}
            format="MM/dd/yyyy"
          />

          <StyledDatePicker
            disabled={selectedLocationId === 0}
            onChange={(date: any) => {
              if (isValid(date)) {
                setEndDate(date.setHours(23, 59, 59, 0))
              }
            }}
            label="End Date"
            value={endDate}
            format="MM/dd/yyyy"
          />
        </Grid>
        {startDate > endDate && (
          <ErrorIndicator
            color={colorPrimitives.darkRed}
            message={'Start Date must be before End Date'}
          />
        )}
        <Stack mt={3} spacing={4}>
          {reportQuery.isError && <ErrorIndicator />}

          {reportQuery.isPending && reportQuery.isFetching ? (
            <ActivityIndicator />
          ) : !reportQuery.data ? (
            <Typography mt={3} variant="h4">
              No data available for the selected location
            </Typography>
          ) : (
            <Box>
              <Box>
                <Typography variant="h3">Download Reports</Typography>

                <Box className="flex lg:justify-end" sx={{ mb: 1 }}>
                  <Button
                    variant="contained"
                    onClick={async () => await handleDownloadSelected()}
                    disabled={selectedLocations.length === 0}
                  >
                    <Typography>Download Selected</Typography>
                  </Button>
                </Box>
              </Box>

              <DataTablePaging
                columns={columns}
                rows={dataRows.results ?? []}
                totalCount={dataRows.rowCount ?? 0}
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                checkboxSelection
                rowSelectionModel={selectedLocations}
                onRowSelectionModelChange={handleOnChange}
              />
            </Box>
          )}
        </Stack>
      </Page>
    </LocalizationProvider>
  )
}
