import { type GridColDef } from '@mui/x-data-grid'
import { formatDateTime } from 'utils/util'

export const RedemptionColumns: GridColDef[] = [
  {
    field: 'patronName',
    headerName: 'Patron Name',
    minWidth: 175,
    flex: 1,
    valueGetter: (params) => params.row.patronName,
  },
  {
    field: 'patronId',
    headerName: 'Patron Id',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.patronId,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'rewardItemId',
    headerName: 'Reward Item Id',
    minWidth: 175,
    flex: 1,
    valueGetter: (params) => params.row.rewardItemId,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'rewardItemName',
    headerName: 'Reward Item Name',
    minWidth: 210,
    flex: 1,
    valueGetter: (params) => params.row.rewardItemName,
  },
  {
    field: 'id',
    headerName: 'Reference #',
    minWidth: 200,
    flex: 0.75,
    valueGetter: (params) => params.row?.id,
  },
  {
    field: 'licensedEstablishmentName',
    headerName: 'Licensed Establishment Name',
    minWidth: 300,
    flex: 1,
    valueGetter: (params) => params.row.licensedEstablishmentName,
  },
  {
    field: 'pointCost',
    headerName: 'Points Cost',
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.pointCost,
  },
  {
    field: 'timeOfredemption',
    headerName: 'Time of Redemption',
    minWidth: 220,
    flex: 1,
    valueGetter: (params) => params.row.timeOfredemption,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
  {
    field: 'rewardProgramName',
    headerName: 'Reward Program Name',
    minWidth: 250,
    flex: 1,
    valueGetter: (params) => params.row.rewardProgramName,
  },
]
