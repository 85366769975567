import { Box, Button, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { useGetMe } from 'hooks/api/useGetMe'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { useState } from 'react'
import { sub, startOfToday, format } from 'date-fns'
import { PageHeader } from 'components/Shared/PageHeader'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridRowSelectionModel,
} from '@mui/x-data-grid'
import { useGetOrganizationsByCorporateAccountId } from 'hooks/api/useGetOrganizationsByCorporateAccountId'
import { RequiredSelectLocations } from 'components/Shared/RequiredSelectLocations'
import { useNavigate } from 'react-router-dom'
import { colorPrimitives } from 'components/Theme'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { useLayout } from 'hooks/useLayout'

export const WeeklySnapshotLocationSelectPage = () => {
  const { isMobile } = useLayout()
  const meQuery = useGetMe()
  const formattedStartDate = format(
    sub(startOfToday(), { days: 7 }),
    'MM/dd/yyyy'
  )
  const formattedEndDate = format(
    sub(startOfToday(), { days: 1 }),
    'MM/dd/yyyy'
  )

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const { data: organizations } = useGetOrganizationsByCorporateAccountId({
    corporateAccountId: Number(selectedCorporateAccountId),
  })

  const [selectedLicenseNumbers, setSelectedLicenseNumbers] =
    useState<GridRowSelectionModel>([])

  const handleSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    setSelectedLicenseNumbers(newSelectionModel)
  }

  const navigate = useNavigate()

  const handleGenerateWeeklySnapshot = async () => {
    await navigate('/GamingReports/WeeklySnapshot', {
      state: { selectedLicenseNumbers },
    })
  }

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.2,
    },
    { field: 'standardName', headerName: 'LE Name', flex: 1 },
    { field: 'id', headerName: 'Account ID', flex: 0.5 },
    { field: 'licenseNumber', headerName: 'License Number', flex: 0.5 },
    {
      field: 'organizationName',
      headerName: 'Associated Organization',
      flex: 1,
    },
  ]

  const mobileColumns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.3,
    },
    { field: 'standardName', headerName: 'LE Name', flex: 1 },
  ]

  const filteredLicensedEstablishments =
    meQuery.data?.licensedEstablishments?.filter(
      (establishment) =>
        establishment.corporateAccountId === selectedCorporateAccountId
    ) ?? []

  const enhanceEstablishmentsWithOrganizationName = () => {
    if (!organizations) return filteredLicensedEstablishments

    // First, enhance establishments with organization name
    let enhanced = filteredLicensedEstablishments.map((le) => {
      const matchingOrg = organizations.find(
        (org) => org.id === le.organizationId
      )
      return {
        ...le,
        organizationName: matchingOrg ? matchingOrg.name : '-',
      }
    })

    // Then, sort the establishments
    enhanced = enhanced.sort((a, b) => {
      // If both have organization names, sort by organization name
      if (a.organizationName !== '-' && b.organizationName !== '-') {
        return a.organizationName.localeCompare(b.organizationName)
      }
      // If one has an organization name and the other doesn't, the one with organization name comes first
      else if (a.organizationName !== '-' && b.organizationName === '-') {
        return -1
      } else if (a.organizationName === '-' && b.organizationName !== '-') {
        return 1
      }
      // If both don't have organization names, sort by location name
      else {
        return a.name.localeCompare(b.name)
      }
    })

    return enhanced
  }

  const enhancedEstablishments = enhanceEstablishmentsWithOrganizationName()

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Weekly Snapshot"
            backText="View Reports"
            subtitle={`Last 7 Days: ${formattedStartDate} - ${formattedEndDate}`}
            backPath="/GamingReports"
            isSecondary={true}
          />
        </Box>
      }
    >
      {!filteredLicensedEstablishments ||
      filteredLicensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <>
          <RequiredSelectLocations />
          <Typography
            variant="h6"
            color={colorPrimitives.darkGray}
            sx={{ mb: 2 }}
          >
            {selectedLicenseNumbers.length} Location
            {selectedLicenseNumbers.length !== 1 ? 's' : ''} Selected
          </Typography>

          <DataGrid
            columns={isMobile ? mobileColumns : columns}
            rows={enhancedEstablishments}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionChange}
            getRowId={(row) => row.licenseNumber}
            hideFooter
          />

          <Box
            sx={{
              ml: 0,
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              variant="contained"
              onClick={handleGenerateWeeklySnapshot}
              disabled={selectedLicenseNumbers.length === 0}
              sx={{ mt: 1 }}
            >
              Generate Report
            </Button>
          </Box>
        </>
      )}

      <Stack spacing={4}>
        {meQuery.isPending && <ActivityIndicator />}
        {meQuery.isError && <ErrorIndicator />}
      </Stack>
    </Page>
  )
}
