import { useApiQuery } from 'hooks/useApiQuery'
import { format } from 'date-fns'
const path = '/le-connect/reports/credits-wagered-by-machine'
const method = 'get'

interface UseGetCreditsWageredByMachineParams {
  licensedEstablishmentId: number | undefined
  startDate: Date
  endDate: Date
}

const formatDate = (d: Date) => format(d, 'yyyy-MM-dd')

export const useGetCreditsWageredByMachine = ({
  licensedEstablishmentId,
  startDate,
  endDate,
}: UseGetCreditsWageredByMachineParams) =>
  useApiQuery({
    path,
    method,
    queryKey: [
      path,
      licensedEstablishmentId,
      startDate.getTime(),
      endDate.getTime(),
    ],
    queryArgs: {
      licensedEstablishmentId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  })
