import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetLicensedEstablishmentManagerImage = ({
  email,
  enabled,
}: {
  email: string
  enabled: boolean
}) => {
  const get = useTypedApiClient({
    path: '/le-connect/licensed-establishments/image/{email}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/le-connect/licensed-establishments/image/{email}', email],
    queryFn: async () => {
      const { data } = await get({ email })
      return data
    },
    enabled,
  })
}
