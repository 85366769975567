import { random } from 'lodash'
import { useGetRewardRedemptionByRewardProgram } from 'hooks/api/Rewards/useGetRewardRedemptionByRewardProgram'
import { DataTable } from 'components/Shared/DataTable'
import { RedemptionColumns } from './RedemptionColumns'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useMemo } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

export const RedemptionSection = ({
  rewardProgramId,
  daysDisplayed,
}: {
  rewardProgramId: number
  daysDisplayed: number
}) => {
  const getStartDate = (daysDisplayed: number) => {
    const today = new Date()
    const daysMap: Record<number, () => void> = {
      7: () => today.setDate(today.getDate() - 7),
      30: () => today.setDate(today.getDate() - 30),
      90: () => today.setMonth(today.getMonth() - 3),
      365: () => {
        today.setMonth(0)
        today.setDate(1)
      },
    }

    daysMap[daysDisplayed]?.()
    return today
  }

  const startTransactionDate = useMemo(() => {
    return getStartDate(daysDisplayed).toLocaleString()
  }, [daysDisplayed])

  const endTransactionDate = useMemo(() => {
    return new Date().toLocaleString()
  }, [])

  const redemptionQuery = useGetRewardRedemptionByRewardProgram({
    rewardProgramId,
    startTransactionDate,
    endTransactionDate,
  })

  let RedemptionColumnsModified = RedemptionColumns
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  if (redemptionQuery.isError) {
    return <p>An error occurred.</p>
  }

  if (redemptionQuery.isPending) {
    return <ActivityIndicator />
  }

  const leData =
    meQuery?.data?.user?.licensedEstablishments?.find(
      (x) =>
        x.licensedEstablishmentId ===
        meQuery?.data?.licensedEstablishments?.find(
          (x) => x.corporateAccountId === selectedCorporateAccountId
        )?.id
    ) ?? undefined

  const showPatronName = leData?.reportsAccessToPIIPermission
  if (!showPatronName) {
    RedemptionColumnsModified = RedemptionColumns.slice(1)
  }

  return (
    <DataTable
      sortFieldName="patronId"
      sort="asc"
      columns={RedemptionColumnsModified}
      rows={
        redemptionQuery.data?.map((redemption) => {
          let displayPatronName = ''
          if (showPatronName) {
            displayPatronName =
              redemption.patron?.firstName + ' ' + redemption.patron?.lastName
          }
          return {
            patronId: redemption.patronId,
            patronName: displayPatronName,
            rewardItemId: redemption.rewardsCatalogReward?.rewardId,
            rewardItemName: redemption.rewardsCatalogReward?.reward?.name,
            licensedEstablishmentName:
              redemption?.licensedEstablishment?.standardName,
            pointCost: redemption.rewardsCatalogReward?.amount,
            timeOfredemption: redemption.createdOn,
            rewardProgramName: redemption.rewardsProgram?.name,
            id: redemption.id,
          }
        }) ?? []
      }
      getRowId={() => random(0, 1000, true)}
    />
  )
}
