import { Box, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { SectionHeader } from 'components/SectionHeader'

export const RelatedAccountsDesktop = ({
  corporateAccountName,
  organizationName,
}: {
  corporateAccountName: string
  organizationName?: string
}) => {
  return (
    <Box>
      <SectionHeader title="Related Accounts" showButton={false} />
      <Grid
        border={{ md: `1px solid ${grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
        container
      >
        <Grid item md={4}>
          <Typography variant="body2" pb={1} color="text.secondary">
            Corporate Account
          </Typography>
          <Typography variant="body1" pb={2}>
            {corporateAccountName || '-'}
          </Typography>
        </Grid>
        {organizationName ? (
          <Grid item md={4}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Organization
            </Typography>

            <Typography variant="body1" pb={2}>
              {organizationName}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}
