import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { PlayerPaybackReportsTabs } from './PlayerPaybackReportsTabs'

export const PlayerPaybackReportsHeader = ({
  currentTab,
  children,
}: {
  currentTab: string
  children?: React.ReactNode
}) => {
  return (
    <Page
      header={
        <PageHeader
          title="View Player Payback Reports"
          isSecondary={true}
          tabs={<PlayerPaybackReportsTabs currentTab={currentTab} />}
        />
      }
    >
      {children}
    </Page>
  )
}
