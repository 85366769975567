import { Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link, useNavigate } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { getLEPermissions } from 'src/navigation/pathPermissions'
import { useSetLe } from 'stores/useSetLe'
import { useEffect } from 'react'

export const PlayerPaybackReportsTabs = ({
  currentTab,
}: {
  currentTab: string
}) => {
  const navigate = useNavigate()
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  ) as LicensedEstablishment[]

  const financialPermission = lePermissions?.[0]?.reportsFinancialPermission
  const nonFinancialPermission =
    lePermissions?.[0]?.reportsNonFinancialPermission

  const setPassedLe = useSetLe((state) => state.setPassedLe)
  const { passedLe } = useSetLe()

  // navigate back to the first tab if there is no LE ID available
  useEffect(() => {
    if (passedLe?.id === undefined) {
      void navigate('/PlayerPaybackReports/PatronActivity')
    }
  }, [passedLe])

  // when the user switches Corp accounts using the select at the left of the screen, set the selected LE to either the one saved in the store or the first available LE if one hasn't been saved
  const setLe = useSetLe((state) => state.setPassedLe)
  useEffect(() => {
    setLe(passedLe ?? licensedEstablishments?.[0])
  }, [selectedCorporateAccountId])

  const nonFinancialTabs = [
    {
      label: 'Patron Activity',
      value: `1`,
      to: `/PlayerPaybackReports/PatronActivity`,
    },
  ]

  const financialTabs = [
    {
      label: 'Patron List',
      value: `2`,
      to: `/PlayerPaybackReports/PatronList`,
    },
    {
      label: 'Patron Reward Redemptions',
      value: `3`,
      to: `/PlayerPaybackReports/PatronRewardRedemptions`,
    },
  ]

  return (
    <div>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={(selectedLe: LicensedEstablishment) => {
          // Update the passed LE
          setPassedLe(selectedLe)
        }}
        locationId={passedLe?.id}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {nonFinancialPermission &&
          nonFinancialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={passedLe === undefined}
            />
          ))}
        {financialPermission &&
          financialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={passedLe === undefined}
            />
          ))}
      </Tabs>
    </div>
  )
}
