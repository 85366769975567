import Box from '@mui/material/Box'
import { formatDateTime } from 'utils/util'
import { type components } from 'api/playerPayback/api'
import { Grid, Typography, useTheme } from '@mui/material'

type promotionType = components['schemas']['FullPromotionDTO']
interface PromotionDetailsProps {
  promotion: promotionType
}

export const PromotionDetailsMobile = ({
  promotion,
}: PromotionDetailsProps) => {
  const theme = useTheme()
  const promotionType = () => {
    if (promotion.type === 'Global') {
      return `J&J Promo`
    } else if (
      promotion.licensedEstablishments &&
      promotion.licensedEstablishments.length > 0
    ) {
      return 'Location Specific'
    } else return '-'
  }

  return (
    <Box className={'pt-4'}>
      <Box>
        <Grid
          container
          gap={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Grid item className="pt-3" xs={8}>
            <Typography variant="h3" pb={2}>
              Special & Event Details
            </Typography>
          </Grid>
        </Grid>

        <Box
          border={{ md: `1px solid ${theme.palette.grey[200]}` }}
          borderRadius={'5px'}
          p={{ xs: 0, sm: 3 }}
        >
          <Grid container className="flex flex-row flex-wrap" columnGap={24}>
            <Grid item sm={8}>
              <Typography variant="body2" pb={1} color="text.secondary">
                Special & Event Name
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.title}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                Start Date
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.startDate
                  ? formatDateTime(promotion.startDate, 'P p')
                  : 'None'}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                End Date
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.endDate
                  ? formatDateTime(promotion.endDate, 'P p')
                  : 'None'}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                Special & Event Type
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotionType()}
              </Typography>
              {promotion.type === 'Location' && (
                <Box>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    LE Name
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {`${promotion.licensedEstablishments?.length} Location(s)`}
                  </Typography>
                </Box>
              )}
              <Typography variant="body2" pb={1} color="text.secondary">
                Description
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.description ?? ''}
              </Typography>

              <Typography variant="body2" pb={1} color="text.secondary">
                Link
              </Typography>
              <Typography variant="body1">
                {promotion.linkLabel ?? '-'}
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.linkURL ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
