import { useMemo, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { type GridColDef, type GridEventListener } from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { Colors, colorPrimitives } from 'components/Theme'
import { useNavigate } from 'react-router-dom'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import {
  type PromotionStatus,
  calculatePromotionStatus,
  dataTablePromotionDateFormatter,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { DataTable } from 'components/Shared/DataTable'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import { useGetPromotionsByCorporateAccountId } from 'hooks/api/useGetPromotionsByCorporateAccountId'
import { type Promotion } from 'src/types/api.ts'
import { getLEPermissions } from 'src/navigation/pathPermissions'
import { useSetContactUsFields } from 'stores/useSetContactUsFields'

const getColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Special & Event Name',
    minWidth: 380,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'Start'),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'End'),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      const startDate = params.row.startDate
        ? new Date(params.row.startDate)
        : null
      const endDate = params.row.endDate ? new Date(params.row.endDate) : null

      const promoStatusLabel = (status: PromotionStatus, isMobile: boolean) => {
        switch (status) {
          case 'scheduled':
            return isMobile ? 'Status: SCHEDULED' : 'SCHEDULED'
          case 'active':
            return isMobile ? 'Status: ACTIVE' : 'ACTIVE'
          case 'expired':
            return isMobile ? 'Status: EXPIRED' : 'EXPIRED'
          default:
            return ''
        }
      }

      const status = calculatePromotionStatus(startDate, endDate)
      return promoStatusLabel(status, isMobile)
    },
    renderCell: (params) => {
      const status = params.value
      const promoBackgroundColor: Record<string, string> = {
        ACTIVE: Colors.promotionsStatus.background.Active,
        EXPIRED: Colors.promotionsStatus.background.Expired,
        SCHEDULED: Colors.promotionsStatus.background.Scheduled,
      }

      return (
        <Box
          style={{
            backgroundColor: promoBackgroundColor[status],
            color: colorPrimitives.black,
            fontWeight: 'bold',
            borderRadius: '4px',
            padding: '6px',
            display: 'inline-block',
          }}
        >
          {status}
        </Box>
      )
    },
  },
]

export const PromotionsPage = () => {
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const navigate = useNavigate()
  const meQuery = useGetMe()
  const promotionsQuery = useGetPromotionsByCorporateAccountId({
    corporateAccountId: Number(selectedCorporateAccountId),
  })
  const { isMobile } = useLayout()
  const [filteredData, setFilteredData] = useState<Promotion[]>()

  const addchangeSpecialsEvents = useSetContactUsFields(
    (state) => state.addchangeSpecialsEvents
  )

  const handleFilteredData = (filteredData: Promotion[]) => {
    setFilteredData(filteredData)
  }

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      void navigate(`/Specials&Events/${params.id}`)
    }
  }

  const sortedPromotions = useMemo(() => {
    if (!promotionsQuery.data) return []

    // Create a shallow copy of the promotions array
    const promotionsCopy = [...promotionsQuery.data]

    // Sort the copied array
    return promotionsCopy.sort((a, b) => {
      if (!a.startDate && !b.startDate) return 0
      if (!a.startDate) return -1
      if (!b.startDate) return 1

      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })
  }, [promotionsQuery.data])

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Specials & Events"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!lePermissions?.[0]?.promotionsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view specials & events. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          <Stack
            direction={{ md: 'row', sm: 'column', xs: 'column' }}
            gap={{ md: 0, sm: 2, xs: 2 }}
            justifyContent={'space-between'}
          >
            <Box className="pb-8" sx={{ width: '50%' }}>
              <GridSearchField<'FullPromotionDTO'>
                placeholder="Search Specials & Events"
                data={sortedPromotions}
                handleFilteredData={handleFilteredData}
                fullWidth
              />
            </Box>
            <Button
              variant="contained"
              onClick={async () => {
                addchangeSpecialsEvents()
                await navigate('/ContactUs')
              }}
            >
              <Typography>Add/Change Specials/Events</Typography>
            </Button>
          </Stack>

          {promotionsQuery.isPending && <ActivityIndicator />}
          {promotionsQuery.isError && <p>An error occured.</p>}
          {!promotionsQuery.isError && promotionsQuery.data && (
            <DataTable
              columns={getColumns(isMobile)}
              rows={filteredData ?? sortedPromotions}
              onCellClick={selectCell}
            />
          )}
        </>
      )}
    </Page>
  )
}
