import { Tab } from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { HeaderStyledTabs } from 'components/Shared/Tabs/HeaderStyledTabs'
import { Link } from 'react-router-dom'
export const SweepstakesDetailsTabs = ({
  currentTab,
  sweepstakeId,
  grandPrizeType,
}: {
  currentTab: any
  sweepstakeId: number
  grandPrizeType: components['schemas']['GrandPrizeType']
}) => {
  return (
    <HeaderStyledTabs currentTab={currentTab}>
      <Tab
        label="Overview"
        value={`/Sweepstakes/${sweepstakeId}/Overview`}
        to={`/Sweepstakes/${sweepstakeId}/Overview`}
        component={Link}
      />

      {grandPrizeType === 'GrandPrizeWithLocation' ? (
        <Tab
          label="My Participating Locations"
          value={`/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
          to={`/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
          component={Link}
        />
      ) : null}

      <Tab
        label="Winners"
        value={`/Sweepstakes/${sweepstakeId}/Winners`}
        to={`/Sweepstakes/${sweepstakeId}/Winners`}
        component={Link}
      />
    </HeaderStyledTabs>
  )
}
