import { Box, Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link, useNavigate } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { getLEPermissions } from 'src/navigation/pathPermissions'
import { useSetLe } from 'stores/useSetLe'
import { useEffect } from 'react'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { sortComparatorByStandardName } from 'utils/util'

export const GamingReportsTabs = ({
  currentTab,
  locationId,
}: {
  currentTab: string
  locationId: number
}) => {
  const navigate = useNavigate()
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const licensedEstablishments = meQuery.data?.licensedEstablishments
    ?.filter((le) => le.corporateAccountId === selectedCorporateAccountId)
    .sort(sortComparatorByStandardName)

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  const setPassedLe = useSetLe((state) => state.setPassedLe)
  const { passedLe } = useSetLe()

  useEffect(() => {
    if (
      !passedLe &&
      licensedEstablishments &&
      licensedEstablishments?.length > 0
    ) {
      setPassedLe(licensedEstablishments[0])
    } else if (passedLe?.id === undefined) {
      void navigate('/GamingReports')
    }
  }, [passedLe])

  if (meQuery.isPending) {
    return <ActivityIndicator />
  }

  if (meQuery.isError) {
    return <ErrorIndicator />
  }

  const financialPermission = lePermissions?.[0]?.reportsFinancialPermission

  const gamingReportsTabs = [
    {
      label: 'Credits Wagered by Machine',
      value: `1`,
      to: `/GamingReports/CreditsWageredByMachine`,
    },
    {
      label: 'Credits Wagered by Hour',
      value: `2`,
      to: `/GamingReports/CreditsWageredByHour`,
    },
    {
      label: 'Credits Wagered: Hourly Trends',
      value: `3`,
      to: `/GamingReports/CreditsWageredHourlyTrends`,
    },
    {
      label: 'Weekly Snapshot',
      value: '',
      to: `/GamingReports/WeeklySnapshotLocationSelect`,
    },
    {
      label: 'NTI Trends',
      value: `4`,
      to: `/GamingReports/TrailingNtiTrends`,
    },
    {
      label: 'VGT Snapshot',
      value: `5`,
      to: `/GamingReports/VgtSnapshot`,
    },
    {
      label: 'VGT Trends',
      value: `6`,
      to: `/GamingReports/VgtTrends`,
    },
  ]

  if (
    !lePermissions?.[0]?.reportsNonFinancialPermission &&
    !lePermissions?.[0]?.reportsFinancialPermission
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          px: '16px',
          py: '16px',
          mt: '1px',
        }}
      >
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view reports. To obtain access, please contact your Account Manager for assistance."
        />
      </Box>
    )
  }

  if (!licensedEstablishments || licensedEstablishments.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          px: '16px',
          py: '16px',
          mt: '1px',
        }}
      >
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      </Box>
    )
  }

  return (
    <>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={(selectedLe: LicensedEstablishment) => {
          // Update the passed LE
          setPassedLe(selectedLe)
        }}
        locationId={passedLe?.id}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {financialPermission &&
          gamingReportsTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
      </Tabs>
    </>
  )
}
