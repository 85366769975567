import { type Path } from './paths'
import {
  type LicensedEstablishment,
  type LicensedEstablishmentUserLicensedEstablishment,
} from 'src/types/api'
import * as _ from 'lodash'
import { includes } from 'lodash'

const allPermissions = [
  'leUsersPermission',
  'liveAtLocationViewPermission',
  'locationsPermission',
  'patronsAccountsPermission',
  'promotionsPermission',
  'reportsFinancialPermission',
  'reportsNonFinancialPermission',
  'rewardsPermission',
  'sweepstakesPermission',
] as const

type PermissionTuple = typeof allPermissions
type Permission = PermissionTuple[number]
type UserPermissionSet = Pick<
  LicensedEstablishmentUserLicensedEstablishment,
  Permission
>

type AlwaysVisiblePath = 'always'
type LoginRequiredPath = 'loginRequired'
type PermissionedPath = Permission | Permission[]

export const pathPermissions: Record<
  Path,
  AlwaysVisiblePath | LoginRequiredPath | PermissionedPath
> = {
  '/': 'always',
  '/CreateAccount': 'always',
  '/Home': 'loginRequired',
  '/Development/Home': 'loginRequired',
  '/Compliance': 'leUsersPermission',
  '/ContactUs': 'loginRequired',
  '/LicensedEstablishments/:id': 'leUsersPermission',
  '/LicensedEstablishmentUsers': 'leUsersPermission',
  '/LicensedEstablishmentUsers/NewLEConnectUserPage': 'leUsersPermission',
  '/LicensedEstablishmentUsers/LEConnectUserDetailsPage/:id':
    'leUsersPermission',
  // '/LiveAtLocation': 'liveAtLocationViewPermission',
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId':
  // 'liveAtLocationViewPermission',
  // '/LiveAtLocation/PatronDetails/:id': 'liveAtLocationViewPermission',
  '/Locations': 'locationsPermission',
  '/PatronCheckIns': 'patronsAccountsPermission',
  '/PlayerPayback': 'reportsNonFinancialPermission',
  '/Specials&Events': 'promotionsPermission',
  '/Specials&Events/:id': 'promotionsPermission',
  '/FlexPay': 'reportsFinancialPermission',
  '/JJNews/Newsletters': 'loginRequired',
  '/JJNews/News': 'loginRequired',
  '/JJNews/Resources': 'loginRequired',
  '/GamingReports': 'reportsFinancialPermission',
  '/GamingReports/GamingReportsPage': 'reportsFinancialPermission',
  '/GamingReports/WeeklySnapshot': 'reportsFinancialPermission',
  '/GamingReports/WeeklySnapshotLocationSelect': 'reportsFinancialPermission',
  '/GamingReports/CreditsWageredByMachine': 'reportsFinancialPermission',
  '/GamingReports/CreditsWageredByHour': 'reportsFinancialPermission',
  '/GamingReports/VgtSnapshot': 'reportsFinancialPermission',
  '/GamingReports/VgtTrends': 'reportsFinancialPermission',
  '/GamingReports/CreditsWageredHourlyTrends': 'reportsFinancialPermission',
  '/GamingReports/TrailingNtiTrends': 'reportsFinancialPermission',
  '/PlayerPaybackReports/PatronRewardRedemptions':
    'reportsNonFinancialPermission',
  '/PlayerPaybackReports/PatronList': 'reportsNonFinancialPermission',
  '/PlayerPaybackReports/PatronActivity': 'reportsNonFinancialPermission',
  '/Sweepstakes': 'sweepstakesPermission',
  '/Sweepstakes/:id/Overview': 'sweepstakesPermission',
  '/Sweepstakes/:id/Winners': 'sweepstakesPermission',
  '/Sweepstakes/:id/ParticipatingLocations': 'sweepstakesPermission',
  '/Sweepstakes/:id/EnrollLocations': 'sweepstakesPermission',
  '/Sweepstakes/:id/AdditionalEnrollmentDetails': 'sweepstakesPermission',
  '/Sweepstakes/:id/ReviewLocationsToEnroll': 'sweepstakesPermission',
  '/Rewards': 'rewardsPermission',
  '/RewardsProgram/:id': 'rewardsPermission',
  // '/RewardsProgram/:id/Settings': 'rewardsPermission',
  '/RewardsProgram/:id/Redemption': 'rewardsPermission',
  '/RewardsProgram/:id/ActivityLog': 'rewardsPermission',
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId': 'rewardsPermission',
}

const pathPermissionsAsRegExps: Array<
  [RegExp, AlwaysVisiblePath | LoginRequiredPath | PermissionedPath]
> = _.map(pathPermissions, (v, k) => {
  return [new RegExp('^' + k.replace(/:\w*id/gi, '\\d+') + '$'), v]
})

const findPathPermission = (path: string) => {
  const match = _.find(pathPermissionsAsRegExps, (x) => x[0].test(path))
  if (_.isNil(match)) {
    return null
  }
  return match[1]
}

// In practice, you can call this with an LicensedEstablishmentUserLicensedEstablishment because
// UserPermissionSet is a strict subset of LicensedEstablishmentUserLicensedEstablishment
export const isPathAvailable = (
  path: Path,
  permissions?: UserPermissionSet[]
): boolean => {
  const pathPermission = findPathPermission(path)

  if (pathPermission === 'always') {
    return true
  }

  if (!permissions) {
    return false
  }

  if (pathPermission === 'loginRequired') {
    return true
  }

  const availablePermissions = userPermissions(permissions?.[0]) // Only need to look at the first collection since the permissions will be set for all based on the getLEPermissionSets function
  const requiredPermissions = [pathPermission].flat()
  return _.intersection(availablePermissions, requiredPermissions).length > 0
}

// Given a UserPermissionSet, build an array of permissions
function userPermissions(userPermissionSet: UserPermissionSet) {
  if (userPermissionSet !== undefined) {
    return allPermissions.reduce<Permission[]>(
      (m, p) => (userPermissionSet[p] ? [...m, p] : m),
      []
    )
  }
}

// This method encapsulates the getLEsForCA, getLEObjectsByLEIds and getLEPermissionSets methods
export const getLEPermissions = (
  leQuery: LicensedEstablishment[] | null | undefined,
  leUserQuery:
    | LicensedEstablishmentUserLicensedEstablishment[]
    | null
    | undefined,
  caId: number | undefined
) => {
  // Get the LE Id's associated with the selected corporate account
  const leIds = getLEsForCA(leQuery, caId)

  // Get the LE objects that include the LE Id's
  const leDatas = getLEObjectsByLEIds(leUserQuery, leIds)

  // For each LE object, check the permission
  // If the permission is true, set all other object permissions to true
  const leDataObjects = getLEPermissionSets(leDatas)

  return leDataObjects
}

// Get the LE Id's associated with the selected corporate account
// This method used with getLEObjectsByLEIds and getLEPermissionSets
export const getLEsForCA = (
  leQuery: LicensedEstablishment[] | null | undefined,
  caId: number | undefined
) => {
  const leIds =
    leQuery?.filter((x) => x.corporateAccountId === caId).map((x) => x.id) ??
    undefined

  return leIds
}

// Get the LE objects that include LE Id's
// This method used with getLEsForCA and getLEPermissionSets
export const getLEObjectsByLEIds = (
  leUserQuery:
    | LicensedEstablishmentUserLicensedEstablishment[]
    | null
    | undefined,
  leIds: number[] | undefined
) => {
  const leDatas =
    leUserQuery?.filter((x) => includes(leIds, x.licensedEstablishmentId)) ??
    undefined

  return leDatas
}

// For each LE object, check the permission
// If the permission is true, set all other object permissions to true
// This method used with getLEsForCA and getLEObjectsByLEIds
export const getLEPermissionSets = (
  leDatas: LicensedEstablishmentUserLicensedEstablishment[] | null | undefined
) => {
  leDatas?.forEach((item) => {
    if (item?.leUsersPermission) {
      leDatas?.forEach((item) => {
        item.leUsersPermission = true
      })
    }
    if (item?.liveAtLocationViewPermission) {
      leDatas?.forEach((item) => {
        item.liveAtLocationViewPermission = true
      })
    }
    if (item?.locationsPermission) {
      leDatas?.forEach((item) => {
        item.locationsPermission = true
      })
    }
    if (item?.patronsAccountsPermission) {
      leDatas?.forEach((item) => {
        item.patronsAccountsPermission = true
      })
    }
    if (item?.promotionsPermission) {
      leDatas?.forEach((item) => {
        item.promotionsPermission = true
      })
    }
    if (item?.reportsFinancialPermission) {
      leDatas?.forEach((item) => {
        item.reportsFinancialPermission = true
      })
    }
    if (item?.reportsNonFinancialPermission) {
      leDatas?.forEach((item) => {
        item.reportsNonFinancialPermission = true
      })
    }
    if (item?.rewardsPermission) {
      leDatas?.forEach((item) => {
        item.rewardsPermission = true
      })
    }
    if (item?.sweepstakesPermission) {
      leDatas?.forEach((item) => {
        item.sweepstakesPermission = true
      })
    }
    if (item?.reportsAccessToPIIPermission) {
      leDatas?.forEach((item) => {
        item.reportsAccessToPIIPermission = true
      })
    }
    if (item?.reportsDownloadPIIPermission) {
      leDatas?.forEach((item) => {
        item.reportsDownloadPIIPermission = true
      })
    }
  })
  const leDataObjects = leDatas as unknown as UserPermissionSet[]
  return leDataObjects
}
