import { Box } from '@mui/material'
import { useLayout } from 'hooks/useLayout'
import { useEffect, useState } from 'react'
import { useHeightActiveModalHeader } from './useHeightActiveModalHeader'

export const ModalFullBody = ({
  children,
  heightModal,
  widthModal,
}: {
  children: React.ReactNode
  heightModal: string
  widthModal?: string
}) => {
  const { isMobile } = useLayout()
  const heightActiveModalHeader = useHeightActiveModalHeader(
    (state) => state.height
  )
  const footerHeight = 80
  const [height, setHeight] = useState<number>()

  const onResize = () => {
    setHeight(
      window.innerHeight * (parseInt(heightModal) / 100) -
        (heightActiveModalHeader + footerHeight)
    )
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [heightActiveModalHeader])

  return (
    <Box
      component="div"
      width={widthModal}
      maxHeight={height}
      height={height}
      overflow={'auto'}
      px={{ xs: 2, sm: 4 }}
      py={{ xs: 3, sm: 4 }}
      sx={isMobile ? { width: '100vw' } : {}}
    >
      {children}
    </Box>
  )
}
