import { create } from 'zustand'

const initialState = {
  zustandRequestType: '',
  zustandSelectedLocation: 0,
}

interface SetContactUsFieldsState {
  zustandRequestType: string
  zustandSelectedLocation: number
  updateSelectedLocation: (zustandSelectedLocation: number) => void
  updateLicenses: () => void
  updateHours: () => void
  flexPay: () => void
  resetRequestType: () => void
  addchangeSpecialsEvents: () => void
}

export const useSetContactUsFields = create<SetContactUsFieldsState>((set) => ({
  ...initialState,
  zustandRequestType: '',
  zustandSelectedLocation: 0,
  updateSelectedLocation: (zustandSelectedLocation: number) => {
    set({ zustandSelectedLocation })
  },
  updateLicenses: () => {
    set({ zustandRequestType: 'UpdateLicenses' })
  },
  updateHours: () => {
    set({ zustandRequestType: 'UpdateLocationInfoHours' })
  },
  addchangeSpecialsEvents: () => {
    set({ zustandRequestType: 'AddOrChangeEvent' })
  },
  flexPay: () => {
    set({ zustandRequestType: 'FlexPay' })
  },
  resetRequestType: () => set(initialState),
}))
