import Box from '@mui/material/Box'
import { BusinessDetailsDesktop } from './BusinessDetails/BusinessDetailsDesktop'
import { AddressDetailsDesktop } from './AddressDetails/AddressDetailsDesktop'
import { ScheduleDetails } from './ScheduleDetails/ScheduleDetails'
import { type LicensedEstablishment } from 'src/types/api'
import { LinksDetailsDesktop } from './LinkDetails/LinkDetailsDesktop'
import { ThumbnailDetails } from './ThumbnailDetails/ThumbnailDetails'
import { RelatedAccountsDesktop } from './RelatedAccounts/RelatedAccountsDesktop'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSetContactUsFields } from 'stores/useSetContactUsFields'

interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const LicensedEstablishmentDetailsDesktop = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  const navigate = useNavigate()
  const updateHours = useSetContactUsFields((state) => state.updateHours)
  const updateLocation = useSetContactUsFields(
    (state) => state.updateSelectedLocation
  )

  return (
    <Box>
      <Box>
        <Box className="flex lg:justify-end" sx={{ mb: 1 }}>
          <Button
            variant="contained"
            onClick={async () => {
              updateHours()
              updateLocation(licensedEstablishment.id)
              await navigate('/ContactUs')
            }}
          >
            <Typography>Update Info/Hours</Typography>
          </Button>
        </Box>
      </Box>
      <Box>
        <BusinessDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        ></BusinessDetailsDesktop>
      </Box>

      <Box className="pt-4">
        <RelatedAccountsDesktop
          corporateAccountName={String(
            licensedEstablishment.corporateAccount?.name
          )}
          organizationName={licensedEstablishment.organization?.name}
        />
      </Box>

      <Box className="pt-4">
        <AddressDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        ></AddressDetailsDesktop>
      </Box>

      <Box className="pt-4">
        <ScheduleDetails
          licensedEstablishment={licensedEstablishment}
        ></ScheduleDetails>
      </Box>

      <Box className="pt-4">
        <ThumbnailDetails
          licensedEstablishment={licensedEstablishment}
        ></ThumbnailDetails>
      </Box>

      <Box className="pt-4">
        <LinksDetailsDesktop licensedEstablishment={licensedEstablishment} />
      </Box>
    </Box>
  )
}
