import { Avatar, Box, Divider, MenuItem, Typography } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import ChevronDown from 'assets/chevron-down.svg'
import ChevronUp from 'assets/chevron-up.svg'
import React from 'react'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { useGetMe } from 'hooks/api/useGetMe'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import _ from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { useLayout } from 'hooks/useLayout'

export const ProfileContent = () => {
  const { isMobile } = useLayout()
  const queryClient = useQueryClient()
  const meQuery = useGetMe()
  const { instance } = useMsal()

  const handleLogout = async () => {
    sessionStorage.removeItem('current-corporate-account')
    try {
      await queryClient.invalidateQueries()
      await instance.logoutRedirect({
        postLogoutRedirectUri: '/',
        account: instance.getActiveAccount(),
      })
    } catch (error) {
      console.log('Failed to Log Out', error)
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl != null) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (meQuery.isPending) {
    return <ActivityIndicator />
  }

  if (meQuery.isError) {
    return <Typography>Something went wrong</Typography>
  }

  const meData = meQuery.data
  const firstName = meData.user?.firstName
  const lastName = meData.user?.lastName

  return (
    <Box
      onClick={toggleMenu}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      gap={1}
    >
      <Avatar
        sx={{
          h: 8,
          w: 8,
          bgcolor: '#F9D578',
          color: 'black',
        }}
      >
        <Typography variant="body-2">
          {[firstName, lastName].map(_.first).join('')}
        </Typography>
      </Avatar>
      {!isMobile && (
        <>
          <Typography
            variant="body-2"
            display="flex"
            alignItems="center"
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
          >
            {firstName} {lastName}
          </Typography>
          <Box display="flex" alignItems="center" p={1}>
            {open ? (
              <img src={ChevronUp} width="20" height="20" />
            ) : (
              <img src={ChevronDown} width="20" height="20" />
            )}
          </Box>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              disableRipple
              sx={{
                height: '54px',
                px: 3,
                backgroundColor: 'white !important',
                cursor: 'default !important',
                '&:hover': {
                  backgroundColor: 'white !important',
                },
              }}
            >
              <Box py={1}>
                <Typography variant="body-2" fontWeight={'normal'}>
                  {firstName} {lastName}
                </Typography>
                <Typography
                  variant="body-2"
                  color={'GrayText'}
                  fontWeight={'normal'}
                >
                  {firstName} {lastName}
                </Typography>
              </Box>
            </MenuItem>

            <Divider style={{ margin: 0 }} />

            <MenuItem
              sx={{
                height: '54px',
                px: 3,
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
              }}
              disableRipple
              onClick={async () => {
                await handleLogout()
              }}
            >
              <Typography variant="label-form" color={'#CC2027'}>
                Sign Out
              </Typography>
            </MenuItem>
          </StyledMenu>
        </>
      )}
    </Box>
  )
}
