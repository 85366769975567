import { Box, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishmentLicense } from 'src/types/api'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

export const LicenseCardHeaderLicensedEstablishmentPage = ({
  licensedEstablishmentLicenseNumber,
  licensedEstablishmentName,
  licensedEstablishmentLicenses,
}: {
  licensedEstablishmentLicenseNumber: string
  licensedEstablishmentName: string
  licensedEstablishmentLicenses: LicensedEstablishmentLicense[]
}) => {
  const theme = useTheme()

  const expired = licensedEstablishmentLicenses.some(
    (e) =>
      e.licenseStatus === 'Expired' &&
      e.licensedEstablishmentLicenseType !== 'Gaming'
  )
  const expiringSoon = licensedEstablishmentLicenses.some(
    (e) =>
      e.licenseStatus === 'ExpiringSoon' &&
      e.licensedEstablishmentLicenseType !== 'Gaming'
  )

  const IconLicenseLicense = () => {
    if (licensedEstablishmentLicenses.length === 0) return null

    if (expired) {
      return (
        <ErrorOutlineOutlinedIcon
          sx={{
            color: theme.palette.error.main,
          }}
        />
      )
    }

    if (expiringSoon) {
      return (
        <ErrorOutlineOutlinedIcon sx={{ color: theme.palette.warning.main }} />
      )
    }

    return <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
  }

  return (
    <Box className="flex items-center">
      <IconLicenseLicense />
      <Typography
        variant="subtitle-2"
        sx={{ paddingLeft: 2, paddingTop: '5px' }}
      >
        {licensedEstablishmentName}
        <Typography
          variant="body-3"
          sx={{ color: theme.palette.text.primary }}
        >{` - License #${licensedEstablishmentLicenseNumber}`}</Typography>
      </Typography>
    </Box>
  )
}
