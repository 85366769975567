import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Zoom,
  MenuItem,
  Select,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { colorPrimitives } from 'components/Theme'
import AccountManagerIcon from 'assets/AccountManagerIcon.svg'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { useGetLicensedEstablishmentManagerImage } from 'hooks/api/useGetLicensedEstablishmentManagerImage'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const AccountManagerMobile = () => {
  const selectedCorporateAccount = useCurrentCorporateAccountStore()
  const [corporateAccountId, setCorporateAccountId] = useState(
    selectedCorporateAccount.currentCorporateAccountId ?? -1
  )
  const [showAccountManager, setShowAccountManager] = useState(false)
  const [locationsShareAccountManager, setLocationsShareAccountManager] =
    useState(false)
  const [selectedLE, setSelectedLE] = useState<number>(-1)
  const [accountManagerName, setAccountManagerName] = useState<
    string | null | undefined
  >('')
  const [accountManagerEmail, setAccountManagerEmail] = useState<
    string | null | undefined
  >('')
  const [accountManagerPhoneNumber, setAccountManagerPhoneNumber] = useState<
    string | null | undefined
  >('')
  const [accountManagerTitle, setAccountManagerTitle] = useState<
    string | null | undefined
  >('')
  const [salesRepName, setSalesRepName] = useState<string | null | undefined>(
    ''
  )
  const [salesRepEmail, setSalesRepEmail] = useState<string | null | undefined>(
    ''
  )
  const [salesRepTitle, setSalesRepTitle] = useState<string | null | undefined>(
    ''
  )
  const [salesRepPhoneNumber, setSalesRepPhoneNumber] = useState<
    string | null | undefined
  >('')
  const licensedEstablishmentsQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId,
    })
  const accountManagerImageQuery = useGetLicensedEstablishmentManagerImage({
    email: accountManagerEmail ? `${accountManagerEmail}.jpg` : '',
    enabled: !!accountManagerEmail,
  })
  const salesRepImageQuery = useGetLicensedEstablishmentManagerImage({
    email: salesRepEmail ? `${salesRepEmail}.jpg` : '',
    enabled: !!salesRepEmail,
  })

  useEffect(() => {
    if (selectedCorporateAccount.currentCorporateAccountId) {
      setCorporateAccountId(selectedCorporateAccount.currentCorporateAccountId)
    }
  }, [selectedCorporateAccount])

  useEffect(() => {
    if (selectedLE !== -1 && licensedEstablishmentsQuery.data) {
      const le = licensedEstablishmentsQuery.data.find(
        (le) => le.id === selectedLE
      )
      if (le !== null && le !== undefined) {
        setAccountManagerName(le.accountManagerName)
        setAccountManagerEmail(le?.accountManagerEmail)
        setAccountManagerPhoneNumber(le?.accountManagerPhoneNumber)
        setAccountManagerTitle(le?.accountManagerJobTitle)
        setSalesRepName(le.salesRepName)
        setSalesRepEmail(le.salesRepEmail)
        setSalesRepPhoneNumber(le.salesRepPhoneNumber)
        setSalesRepTitle(le?.salesRepJobTitle)
      }
    }
  }, [selectedLE])

  useEffect(() => {
    if (
      licensedEstablishmentsQuery.data &&
      licensedEstablishmentsQuery.data.length > 0
    ) {
      setSelectedLE(licensedEstablishmentsQuery.data[0].id)
      const firstAccountManagerName =
        licensedEstablishmentsQuery.data[0].accountManagerName
      const firstSalesRepName = licensedEstablishmentsQuery.data[0].salesRepName
      setLocationsShareAccountManager(
        licensedEstablishmentsQuery.data.every(
          (le) =>
            le.accountManagerName === firstAccountManagerName &&
            le.salesRepName === firstSalesRepName
        )
      )
    } else {
      setSelectedLE(-1)
      setAccountManagerName('')
      setAccountManagerEmail('')
      setAccountManagerPhoneNumber('')
      setAccountManagerTitle('')
      setSalesRepName('')
      setSalesRepEmail('')
      setSalesRepPhoneNumber('')
      setSalesRepTitle('')
      setLocationsShareAccountManager(true)
    }
  }, [licensedEstablishmentsQuery.data])

  if (licensedEstablishmentsQuery.isPending) {
    return <CircularProgress />
  }

  if (licensedEstablishmentsQuery.isError) {
    return <p>An error occurred.</p>
  }

  const isNotNull = (s: string | null | undefined): s is string => {
    return !isNil(s) && s !== ''
  }

  const boxClassName = `w-5/6 fixed right-7 rounded-l rounded-r-xl md:rounded-r-full text-white flex justify-start ${
    !locationsShareAccountManager ? 'h-[350px] bottom-2' : 'h-[300px] bottom-5'
  }`

  return (
    <>
      <Zoom in={showAccountManager}>
        <Box className={boxClassName} bgcolor={colorPrimitives.darkRed}>
          <Stack direction="column" width="100%">
            <Stack justifyContent={'center'} style={{ padding: 3 }}>
              {!locationsShareAccountManager &&
              licensedEstablishmentsQuery.data.length > 1 ? (
                <FormControl size="small">
                  <Select
                    onChange={(event) => {
                      setSelectedLE(Number(event.target.value))
                    }}
                    value={selectedLE}
                    sx={{
                      width: '100%',
                      marginBottom: '3px',
                      color: 'white', // Font color
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color on hover
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color when focused
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'white', // Arrow color
                      },
                    }}
                  >
                    {licensedEstablishmentsQuery.data.map((le) => {
                      return (
                        <MenuItem key={le.id} value={le.id}>
                          {le.name} (#{le.licenseNumber})
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              width="100%"
              padding={1}
            >
              {accountManagerImageQuery.data ? (
                <Stack
                  style={{
                    minWidth: '60px',
                    maxWidth: '60px',
                    minHeight: '60px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SimpleImageCard
                    imageSource={accountManagerImageQuery.data}
                  />
                </Stack>
              ) : (
                <img
                  src={AccountManagerIcon}
                  width={60}
                  height={60}
                  alt="Account Manager Icon"
                />
              )}
              <Stack spacing={1}>
                {isNotNull(accountManagerName) ||
                isNotNull(accountManagerEmail) ||
                isNotNull(accountManagerPhoneNumber) ? (
                  <>
                    <Stack justifyContent={'center'}>
                      <Typography
                        lineHeight={'18px'}
                        variant="subtitle-2"
                        fontWeight={700}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {accountManagerName}
                      </Typography>
                      <Typography
                        lineHeight={'18px'}
                        variant="label-form"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {accountManagerTitle}
                      </Typography>
                    </Stack>
                    <Stack className="whitespace-nowrap">
                      <Typography variant="body-2" lineHeight={'16px'}>
                        {accountManagerPhoneNumber?.replace(/ +/g, '')}
                      </Typography>
                      <Typography
                        variant="body-2"
                        lineHeight={'16px'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {accountManagerEmail?.replace(/ +/g, '')}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Typography>No Account Manager assigned</Typography>
                )}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              width="100%"
              padding={1}
            >
              {salesRepImageQuery.data ? (
                <Stack
                  style={{
                    minWidth: '60px',
                    maxWidth: '60px',
                    minHeight: '60px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SimpleImageCard imageSource={salesRepImageQuery.data} />
                </Stack>
              ) : (
                <img
                  src={AccountManagerIcon}
                  width={60}
                  height={60}
                  alt="Account Manager Icon"
                />
              )}
              <Stack spacing={1}>
                {isNotNull(salesRepName) ||
                isNotNull(salesRepEmail) ||
                isNotNull(salesRepPhoneNumber) ? (
                  <>
                    <Stack justifyContent={'center'}>
                      <Typography
                        lineHeight={'18px'}
                        variant="subtitle-2"
                        fontWeight={700}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {salesRepName}
                      </Typography>
                      <Typography
                        lineHeight={'18px'}
                        variant="label-form"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {salesRepTitle}
                      </Typography>
                    </Stack>
                    <Stack className="whitespace-nowrap">
                      <Typography variant="body-2" lineHeight={'16px'}>
                        {salesRepPhoneNumber?.replace(/ +/g, '')}
                      </Typography>
                      <Typography
                        variant="body-2"
                        lineHeight={'16px'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {salesRepEmail?.replace(/ +/g, '')}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Typography>No Sales Rep assigned</Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Zoom>
      <Box className="fixed bottom-5 right-7 rounded-full text-white flex justify-end items-center">
        <IconButton
          aria-label="accountmanagerbutton"
          focusRipple
          sx={{
            backgroundColor: colorPrimitives.redGaming,
            boxShadow: 10,
          }}
          className={`w-[80px] h-[80px] md:w-[100px] md:h-[100px] ${
            !locationsShareAccountManager ? 'bottom-2' : 'bottom-0'
          }`}
          onClick={() => {
            setShowAccountManager((prev) => !prev)
          }}
        >
          <img src={AccountManagerIcon} alt="Account Manager Icon" />
        </IconButton>
      </Box>
    </>
  )
}
