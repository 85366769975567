import { Box, Button, MenuItem, Typography } from '@mui/material'
import { SweepstakesDetailsHeader } from '../../SweepstakeDetailsHeader'
import { useParams } from 'react-router-dom'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { isNil } from 'lodash'
import { DataTable } from 'components/Shared/DataTable'
import noPatronsCheckedIn from 'assets/NoPatronsCheckedIn.png'
import {
  type GridRenderCellParams,
  type GridColDef,
  type GridRowParams,
} from '@mui/x-data-grid'
import { useState, useMemo, useEffect } from 'react'
import ListBullet from 'assets/format_list_bulleted.svg'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { Colors } from 'components/Theme'
import { colors } from 'components/ThemeProvider'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { type SweepstakeWinnerStatusType } from 'src/types/api'
import { SweepstakesRequestRedrawModal } from './SweepstakesRequestRedrawModal'
import { usePutSweepstakeWinner } from 'hooks/api/usePutSweepstakeWinner'

export const SweepstakesWinnersPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccount = useCurrentCorporateAccountStore()
  const { id: idParam } = useParams()

  // Redraw Winners Modal
  const [isRequestRedrawModalOpen, setIsRequestRedrawModalOpen] =
    useState(false)
  const toggleRequestRedrawModalIsOpen = () =>
    setIsRequestRedrawModalOpen(!isRequestRedrawModalOpen)
  const sweepstakeQuery = useGetSweepstakeById({
    sweepstakeId: Number(idParam),
  })

  const handleRowClick = (params: GridRowParams) => {
    const clickedRow = params.row
    setSelectedWinner({
      locationName: clickedRow.leName,
      licenseNumber: clickedRow.leLicenseNumber,
      winnerName: clickedRow.winnerName,
      sweepstakesWinner: clickedRow.dto,
    })
  }

  const locationWinners = useMemo(() => {
    if (!sweepstakeQuery.data || !meQuery.data || !selectedCorporateAccount) {
      return []
    }

    const allowedEstablishments = new Set(
      meQuery.data?.licensedEstablishments?.map((le) => le.id)
    )
    const allLocationDrawings =
      sweepstakeQuery.data.sweepstakeDrawings?.filter(
        (d) =>
          d.type === 'Location' &&
          d.licensedEstablishment?.id !== undefined &&
          allowedEstablishments.has(d.licensedEstablishment?.id) &&
          d.licensedEstablishment?.corporateAccount?.id ===
            selectedCorporateAccount.currentCorporateAccountId
      ) ?? []

    // Use a Map to keep track of the most recent drawing for each licensedEstablishmentId
    const locationDrawingsByEstablishment = new Map<
      number,
      (typeof allLocationDrawings)[0]
    >()

    allLocationDrawings.forEach((ld) => {
      const establishmentId = ld.licensedEstablishment?.id
      if (establishmentId != null) {
        // Always set the latest occurrence of the location drawing
        locationDrawingsByEstablishment.set(establishmentId, ld)
      }
    })

    // Extract the values from the map, which are the most recent drawings for each establishment
    const locationDrawings = Array.from(
      locationDrawingsByEstablishment.values()
    )

    return locationDrawings
      .flatMap(
        (ld) =>
          ld.sweepstakeWinners?.map((winner) => {
            const fullName = `${winner.patron?.firstName} ${winner.patron?.lastName}`
            const validWinnerName = fullName.trim() !== ''
            return {
              id: winner.id,
              leName: ld.licensedEstablishment?.standardName ?? '???',
              leLicenseNumber: validWinnerName
                ? `#${ld.licensedEstablishment?.licenseNumber}`
                : '-',
              winnerName: validWinnerName ? fullName : '-',
              patronId: winner.patronId,
              winnerStatus: winner.status ?? 'NoWinner',
              dto: winner,
              resultType: winner.resultType,
              drawingSequence: winner.drawingSequence,
              drawnDate: winner.dateDrawn ?? '-',
            }
          }) ?? []
      )
      .filter(
        (winner) =>
          winner.resultType === 'Winner' || winner.winnerStatus === 'NoWinner'
      )
      .sort((a, b) => {
        const priority = [
          'RedrawRequest',
          'WinnerValidation',
          'WinnerNotified',
          'AffidavitReceived',
        ]

        const aIndex = priority.indexOf(a.winnerStatus)
        const bIndex = priority.indexOf(b.winnerStatus)

        if (aIndex !== -1 && bIndex !== -1) {
          if (aIndex === bIndex) {
            // Statuses are same, so order by leName alphabetically
            return a.leName.localeCompare(b.leName)
          }
          // both statuses are in the priority list, compare their indices
          return aIndex - bIndex
        } else if (aIndex !== -1) {
          // only a's status is in the priority list, a comes first
          return -1
        } else if (bIndex !== -1) {
          // only b's status is in the priority list, b comes first
          return 1
        }

        // neither status is in the priority list, order by leNAme alphabetically
        return a.leName.localeCompare(b.leName)
      })
  }, [sweepstakeQuery.data, meQuery.data, selectedCorporateAccount])

  useEffect(() => {
    if (locationWinners.length > 0) {
      setSelectedWinner({
        locationName: locationWinners[0].leName,
        licenseNumber: locationWinners[0].leLicenseNumber,
        winnerName: locationWinners[0].winnerName,
        sweepstakesWinner: locationWinners[0].dto,
      })
    }
  }, [locationWinners])

  const [selectedWinner, setSelectedWinner] = useState({
    locationName: '',
    licenseNumber: '',
    winnerName: '',
    sweepstakesWinner: locationWinners[0]?.dto,
  })

  const putSweepstakeWinnerMutation = usePutSweepstakeWinner({
    onSuccess: () => {},
    onError: () => {},
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl != null) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const statusTextMap: Record<SweepstakeWinnerStatusType, string> = {
    LocationValidation: 'LOCATION VALIDATION',
    RedrawRequest: 'REDRAW REQUESTED',
    WinnerValidation: 'WINNER VALIDATED',
    WinnerNotified: 'WINNER NOTIFIED',
    AffidavitReceived: 'AFFIDAVIT RECEIVED',
    Awarded: 'AWARDED',
    PrizeClaim: 'PRIZE CLAIMED',
    NoWinner: 'NO WINNER',
    GrandPrizeValidation: 'Grand Prize Validation',
  }

  const getStatusStyleColors = (winnerStatus: SweepstakeWinnerStatusType) => {
    const background = Colors.sweepstakesWinnerStatus.background[winnerStatus]
    const color = Colors.sweepstakesWinnerStatus.text[winnerStatus]

    return {
      background,
      color,
    }
  }

  const locationWinnersColumns: GridColDef[] = [
    {
      field: 'leName',
      minWidth: 200,
      headerName: 'Licensed Establishment',
      flex: 1,
    },
    {
      field: 'leLicenseNumber',
      minWidth: 200,
      headerName: 'License Number',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography color={colors.text.primary}>{params.value}</Typography>
      ),
    },
    {
      field: 'winnerName',
      minWidth: 200,
      headerName: 'Winner Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography color={colors.text.primary}>{params.value}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Winner Status',
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const winnerStatus = params.row
          .winnerStatus as SweepstakeWinnerStatusType
        const statusText =
          params.row.winnerName === '-'
            ? 'NO WINNER'
            : statusTextMap[winnerStatus]
        const style = getStatusStyleColors(winnerStatus)
        return (
          <Box
            className="px-2 pt-1 pb-0.5 rounded-sm font-bold uppercase"
            sx={{ ...style }}
          >
            {statusText}
          </Box>
        )
      },
    },
    {
      field: 'actions',
      minWidth: 50,
      headerName: '',
      renderCell: (params: GridRenderCellParams) => {
        const hasLocationValidationWinner =
          params.row.winnerName &&
          params.row.winnerName !== '-' &&
          params.row.winnerStatus === 'LocationValidation'
        return (
          hasLocationValidationWinner && (
            <Button onClick={(e) => toggleMenu(e)}>
              <img src={ListBullet} width={4} height={16} alt="options icon" />
            </Button>
          )
        )
      },
    },
  ]

  if (sweepstakeQuery.isPending) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError || isNil(sweepstakeQuery.data)) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  const sweepstake = sweepstakeQuery.data
  const showLocationWinners =
    sweepstake.sweepstakeData?.state !== 'InternalValidation'

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Sweepstakes/${sweepstake.id}/Winners`}
    >
      {sweepstake.grandPrizeType === 'GrandPrizeWithLocation' ? (
        <Box className="-mt-12">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 3,
              marginTop: 6,
            }}
          >
            <Typography variant="h2">My Location Winners</Typography>
          </Box>
          <DataTable
            onRowClick={handleRowClick}
            columns={locationWinnersColumns}
            rows={showLocationWinners ? locationWinners : []}
            imageSource={noPatronsCheckedIn}
            noDataMessage="After the sweepstake ends and winners are drawn, location winners will be posted here."
          />
        </Box>
      ) : null}
      <SweepstakesRequestRedrawModal
        locationName={selectedWinner.locationName}
        licenseNumber={selectedWinner.licenseNumber}
        winnerName={selectedWinner.winnerName}
        sweepstakesWinner={selectedWinner.sweepstakesWinner}
        isModalOpen={isRequestRedrawModalOpen}
        toggleIsOpen={toggleRequestRedrawModalIsOpen}
        onConfirm={(reason) => {
          putSweepstakeWinnerMutation.mutate({
            sweepstakeDrawingId:
              selectedWinner.sweepstakesWinner.sweepstakeDrawingId,
            patronId: selectedWinner.sweepstakesWinner.patronId,
            contestEntryId: selectedWinner.sweepstakesWinner.contestEntryId,
            redrawReason: reason,
            id: selectedWinner.sweepstakesWinner.id ?? 0,
            status: 'RedrawRequest',
          })
          toggleRequestRedrawModalIsOpen()
        }}
        onCancel={toggleRequestRedrawModalIsOpen}
      />
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            setIsRequestRedrawModalOpen(true)
          }}
        >
          <Box className="py-2">
            <Typography variant="body-2">Request Redraw</Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
    </SweepstakesDetailsHeader>
  )
}
