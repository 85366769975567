import { Box, CircularProgress } from '@mui/material'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { LicenseDetailLicensedEstablishmentPage } from './LicenseDetailLicensedEstablishmentPage'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { type LicensedEstablishment } from 'src/types/api'

export const LicensesListLicensedEstablishmentPage = () => {
  const selectedCorporateAccount = useCurrentCorporateAccountStore()
  const licensedEstablishmentsQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId: Number(
        selectedCorporateAccount.currentCorporateAccountId
      ),
    })

  if (licensedEstablishmentsQuery.isPending) {
    return <CircularProgress />
  }

  if (licensedEstablishmentsQuery.isError) {
    return <p>An error occurred.</p>
  }

  const sortedLicensedEstablishments = licensedEstablishmentsQuery.data
    ? [...licensedEstablishmentsQuery.data].sort(
        (a: LicensedEstablishment, b: LicensedEstablishment) => {
          return (a.standardName ?? '') > (b.standardName ?? '') ? 1 : -1
        }
      )
    : []

  return (
    <Box sx={{ py: 1 }}>
      {sortedLicensedEstablishments.map((licensedEstablishment) => {
        return (
          <LicenseDetailLicensedEstablishmentPage
            key={licensedEstablishment.id}
            licensedEstablishmentLicenseNumber={
              licensedEstablishment.licenseNumber
            }
            licensedEstablishmentName={
              licensedEstablishment.standardName ?? licensedEstablishment.name
            }
            licensedEstablishmentLicenses={
              licensedEstablishment.licensedEstablishmentLicenses ?? []
            }
            uniqueLicensedEstablishment={
              sortedLicensedEstablishments.length === 1
            }
          />
        )
      })}
    </Box>
  )
}
