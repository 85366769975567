import { endOfWeek, format, parse, parseISO, startOfWeek, sub } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { isEmpty, isNil } from 'lodash'
import type { Address } from 'src/types/address'

export const camelCaseToTitleCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export type DayName =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

export const getDayName = (d: Date): DayName => {
  const days: Record<number, DayName> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  }

  return days[d.getUTCDay()]
}

export const daysOfWeek: DayName[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export type jjConnectUserRoleType =
  | 'Owner'
  | 'Staff'
  | 'SalesAccountManager'
  | 'LimitedLiveAtLocation'
  | 'SalesServiceRep'

export const convertTimestampTo24HourFormat = (
  timeStamp: string | Date
): string => {
  if (typeof timeStamp === 'string') {
    return format(parse(timeStamp, 'hh:mm a', new Date()), 'HH:mm:ss')
  } else {
    return format(timeStamp, 'HH:mm:ss')
  }
}

export const convertScheduleHourTo12HourFormat = (
  timeStamp: string
): string => {
  // Remove the fractional seconds part if that ever makes it in
  const cleanTimeStamp = timeStamp.split('.')[0]
  const date = parse(cleanTimeStamp, 'HH:mm:ss', new Date())
  return format(date, 'hh:mm a')
}

export const formatAddress = (
  addresses: Address[],
  isCompleteAddress = false
) => {
  if (isEmpty(addresses)) {
    return 'Unspecified Address'
  }
  const { address1, address2, city, state, postalCode } = addresses[0]
  const parts = []
  if (!isEmpty(address1)) {
    parts.push(address1)
  }
  if (!isEmpty(address2) && isCompleteAddress) {
    parts.push(address2)
  }
  if (!isEmpty(city)) {
    parts.push(city)
  }
  if (!isEmpty(state)) {
    parts.push(state)
  }
  if (!isEmpty(postalCode) && isCompleteAddress) {
    parts.push(postalCode)
  }
  return parts.join(', ')
}

export const formatFullDate = (stringDate: stringDate) => {
  if (isNil(stringDate)) {
    return
  }

  const date = parseISO(stringDate)
  return `${format(date, 'MM/dd/yyyy')}`
}

export const dataTableAddressFormatter =
  (isCompleteAddress?: boolean) => (params: { value: Address[] }) =>
    formatAddress(params.value ?? [], isCompleteAddress)

export const dataTableIdFormatter =
  (isMobile: boolean) => (params: { value: string }) => {
    return isMobile ? `ID # ${params.value}` : params.value
  }

export const formatDateTime = (
  stringDate: string | null | undefined,
  formatString: string = 'PPpp'
) => {
  if (isNil(stringDate)) return

  const date = new Date(stringDate)

  return format(date, formatString)
}

type stringDate = string | null | undefined
export const formatFullDateTime = (stringDate: stringDate) => {
  if (isNil(stringDate)) {
    return
  }

  const date = parseISO(stringDate)
  return `${format(date, 'MM-dd-yyyy')} ${format(date, 'p')}`
}

export const formatDateTimeInUTC = (stringDate: string | null | undefined) => {
  if (isNil(stringDate)) return

  const date = new Date(stringDate)
  const format = date.getMonth() === 4 ? 'MMM dd, yyy' : 'MMM. dd, yyyy'

  return formatInTimeZone(date, 'Etc/UTC', format)
}

export const getLastUpdated = (
  modifiedOn: stringDate,
  formatString?: string
) =>
  modifiedOn
    ? `Last Updated: ${formatDateTime(modifiedOn, formatString)}`
    : undefined

export const getReportStartAndEndDates = (
  startWeeksAgo: number,
  endWeeksAgo: number
) => {
  const today = new Date()
  // Monday
  const startDate = startOfWeek(sub(today, { weeks: startWeeksAgo }), {
    weekStartsOn: 0,
  })
  // Sunday
  const endDate = endOfWeek(sub(today, { weeks: endWeeksAgo }), {
    weekStartsOn: 1,
  })

  return { startDate, endDate }
}

export const dateDifferenceInDays = (startDate: Date, endDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const diffInTime = endDate.getTime() - startDate.getTime()
  return Math.round(diffInTime / oneDay)
}

export const formatLatitude = (latitude: number) => {
  if (latitude > 0) {
    return `${latitude}° N`
  }
  return `${latitude}° S`
}

export const formatLongitude = (longitude: number) => {
  if (longitude > 0) {
    return `${longitude}° E`
  }
  return `${longitude}° W`
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export const dataTableLicenseNumberFormatter =
  (isMobile: boolean) => (params: { value: string }) => {
    return isMobile ? `License #: ${params.value}` : params.value
  }

export const minimumSearchLength = 3

export const pdfDownloadFromByteArray = (data: string, fileName: string) => {
  const byteCharacters = atob(data)
  const byteNumbers = new Uint8Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const blob = new Blob([byteNumbers], { type: 'application/pdf' })

  // Create a download link and trigger download
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()

  URL.revokeObjectURL(link.href)
}

export type PromotionStatus = 'scheduled' | 'active' | 'expired'
export const calculatePromotionStatus = (
  startDate: Date | null,
  endDate: Date | null
): PromotionStatus => {
  const now = new Date()
  if (startDate && endDate) {
    if (now >= startDate && now <= endDate) {
      return 'active'
    } else if (now > endDate) {
      return 'expired'
    } else if (now < startDate) {
      return 'scheduled'
    }
  }
  return 'active'
}

export const dataTablePromotionDateFormatter =
  (isMobile: boolean, startOrEnd: string) => (params: { value: Date }) => {
    if (params.value) {
      const startDate = new Date(params.value)
      if (!isMobile) {
        return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
          [],
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )}`
      }
      if (isMobile) {
        return `${startOrEnd} Date: ${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
          [],
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )}`
      }
    } else {
      return isMobile ? `${startOrEnd} Date: None` : 'None'
    }
  }

export const transformSelectedLocations = (selectedLocations: string[]) => {
  const transformedData = {
    licensedEstablishmentIds: Array<number>(),
    selectedCorporateAccountIds: Array<number>(),
    organizationIds: Array<number>(),
  }

  selectedLocations.forEach((location) => {
    const [type, id] = location.split('-')

    switch (type) {
      case 'licensedEstablishment':
        transformedData.licensedEstablishmentIds.push(Number(id))
        break
      case 'organization':
        transformedData.organizationIds.push(Number(id))
        break
      case 'corporateAccount':
        transformedData.selectedCorporateAccountIds.push(Number(id))
        break
    }
  })

  return { data: transformedData }
}

export const formatLEUserType = (type: string | undefined): string => {
  if (isNil(type)) {
    return ''
  }
  return type.split(/(?=[A-Z])/).join(' ')
}

export const formatCurrency = (
  amount: number,
  locale = 'en-US',
  currency = 'USD'
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencySign: 'accounting',
  }).format(amount)
}

export const sortComparatorByName = (
  a: { name?: string | null },
  b: { name?: string | null }
) => {
  const nameA = a.name ?? ''
  const nameB = b.name ?? ''
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export const sortComparatorByStandardName = (
  a: { standardName?: string | null },
  b: { standardName?: string | null }
) => {
  const nameA = a.standardName ?? ''
  const nameB = b.standardName ?? ''
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}
